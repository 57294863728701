<template>
    <section class="sezioneShare">
        <div class="cont">
            <div :class="['hoverZone', { active: shareActive }]" @click="toggleShare" ref="hoverZone">
                <div class="buttonZone">
                    <div class="buttonCont">
                        <div class="introCont">
                            <NIcon size="1.176rem" color="var(--color-background-gray)" :component="ShareFilled" :aria-label="$t('condividi')"></NIcon>
                        </div>

                        <NTooltip trigger="hover">
                            <template #trigger>
                                <a class="iconCont" :href="`https://www.facebook.com/sharer/sharer.php?u=${currentAddress}`" target="_blank" @click="closeShare">
                                    <NIcon size="1.176rem" color="var(--color-azure-dark)" aria-label="Facebook">
                                        <IconFacebook />
                                    </NIcon>
                                </a>
                            </template>
                            {{ $t('condividi_su') }} Facebook
                        </NTooltip>
                        <NTooltip trigger="hover">
                            <template #trigger>
                                <a class="iconCont" :href="`fb-messenger://share/?link=${currentAddress}`" target="_blank" @click="closeShare">
                                    <NIcon size="1.176rem" color="var(--color-azure-dark)" aria-label="Messenger">
                                        <IconFacebookMsn />
                                    </NIcon>
                                </a>
                            </template>
                            {{ $t('condividi_su') }} Messenger
                        </NTooltip>
                        <NTooltip trigger="hover">
                            <template #trigger>
                                <a class="iconCont" :href="`whatsapp://send?text=${currentAddress}`" target="_blank" @click="closeShare">
                                    <NIcon :size="21" color="var(--color-azure-dark)" aria-label="WhatsApp">
                                        <IconWhatsApp />
                                    </NIcon>
                                </a>
                            </template>
                            {{ $t('condividi_su') }} WhatsApp
                        </NTooltip>
                        <NTooltip trigger="hover">
                            <template #trigger>
                                <a class="iconContM" :href="`tg://msg_url?url=${currentAddress}`" target="_blank" @click="closeShare">
                                    <NIcon :size="21" color="var(--color-azure-dark)" aria-label="Telegram">
                                        <IconTelegram />
                                    </NIcon>
                                </a>
                            </template>
                            {{ $t('condividi_su') }} Telegram
                        </NTooltip>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NTooltip, NIcon } from 'naive-ui';
import { ShareFilled } from '@vicons/material'
const route = useRoute()

const hoverZone = ref()
const shareActive = ref(false)

const currentAddress = computed(() => {
    return encodeURIComponent(`https://turismotorino.org${route.path.replace(/\/$/, "")}`)
})

const toggleShare = () => {
    shareActive.value = !shareActive.value
}
const closeShare = () => {
    shareActive.value = false
}
onClickOutside(hoverZone, () => { shareActive.value = false }) 
</script>

<style lang="scss" scoped>
.sezioneShare {
    margin-bottom: calc(0px - var(--margin-double));

    .hoverZone {
        width: 220px;
    }

    .buttonZone {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: white;
        overflow: hidden;

        transform: translateZ(0);
        transition: width 150ms 0ms ease-in-out;

        .buttonCont {

            width: 220px;
            display: flex;

            .introCont {
                height: 40px;
                width: 38px;
                padding-right: 2px;
                display: flex;
                justify-content: center;
                align-items: center;

                transition: opacity 150ms 0ms ease-in-out;
            }

            .iconCont {
                cursor: pointer;
                height: 40px;
                width: 40px;

                display: flex;
                justify-content: center;
                align-items: center;
            }

            .iconContM {
                cursor: pointer;
                height: 40px;
                width: 34px;
                padding-right: 6px;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media (hover:hover) {
    .sezioneShare {
        .hoverZone:hover .buttonZone {
            width: 220px;

            .buttonCont {
                .introCont {
                    opacity: 0.5;
                }
            }
        }
    }
}

@media (hover:none) {
    .sezioneShare {
        .hoverZone.active .buttonZone {
            width: 220px;

            .buttonCont {
                .introCont {
                    opacity: 0.5;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .sezioneShare {
        margin-bottom: 0;
    }
}
</style>