<template>
    <section class="moduloMedia" v-if="media">
        <div class="cont">
            <div :class="[{ innerCont: size == 'standard' }, { thirdCont: size == 'small' }]">
                <div class="controls">
                    <NButton size="medium" secondary circle @click="swipePrev" tabindex="-1" :aria-label="$t('indietro')">
                        <template #icon>
                            <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowLeftRound" aria-hidden="true" :aria-label="$t('indietro')"></NIcon>
                        </template>
                    </NButton>
                    <NButton size="medium" secondary circle @click="swipeNext" tabindex="-1" :aria-label="$t('avanti')">
                        <template #icon>
                            <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" aria-hidden="true" :aria-label="$t('avanti')"></NIcon>
                        </template>
                    </NButton>
                </div>
                <swiper-container :modules="[Autoplay]" :autoplay="autoplayOptions" :slides-per-view="1" :slides-per-group="1" :space-between="6" :grab-cursor="true" :loop="true" ref="swiper">
                    <template v-for="image, i in media">
                        <swiper-slide class="imageCont swiper-slide-active" v-if="i == 0">
                            <Image :image="image.directus_files_id" :width="800" imageClass="cover" />
                        </swiper-slide>
                        <swiper-slide class="imageCont swiper-slide-next" v-else-if="i == 1">
                            <Image :image="image.directus_files_id" :width="800" imageClass="cover" />
                        </swiper-slide>
                        <swiper-slide class="imageCont" v-else>
                            <Image :image="image.directus_files_id" :width="800" imageClass="cover" />
                        </swiper-slide>
                    </template>
                </swiper-container>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui'
import { Autoplay } from 'swiper/modules';
import { KeyboardArrowLeftRound, KeyboardArrowRightRound } from '@vicons/material'

interface Props {
    media: any | null;
    size?: 'small' | 'standard';
}

const props = withDefaults(defineProps<Props>(), {
    size: 'standard'
})
const swiper = ref<any>(null)

const autoplayOptions = ref({
    delay: 8000,
    disableOnInteraction: true,
})

const swipeNext = () => {
    if (swiper.value) swiper.value.swiper.slideNext()
}
const swipePrev = () => {
    if (swiper.value) swiper.value.swiper.slidePrev()
}
</script>

<style lang="scss" scoped>
.moduloMedia {
    .cont {
        .innerCont {
            height: 500px;
            position: relative;
            border-radius: var(--size-card-radius);
            overflow: hidden;
        }

        .thirdCont {
            height: 300px;
            position: relative;
            border-radius: var(--size-card-radius);
            overflow: hidden;

            .imageCont {
                height: 300px;
            }
        }
    }

    .imageCont {
        position: relative;
        height: 500px;
        width: 100%;

        background-color: var(--color-background-image);
        border-radius: var(--size-card-radius);
        overflow: hidden;
    }

    .controls {
        position: absolute;
        z-index: 5;
        top: 0;
        left: var(--margin-default);
        right: var(--margin-default);
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
    .moduloMedia {
        .cont .innerCont {
            height: 300px;
        }

        .imageCont {
            height: 300px;
        }
    }
}
</style>